@use "../config/" as *;
.waf-static {
	span {
		display: block;
	}
	.layout-wrapper {
		@extend %py-10;
		@extend %px-4;
	}
	.title {
		@extend %pb-5;
		@extend %font-20-pb;
	}
	.body-wrap {
		line-height: var(--space-5);
		background-color: var(--pure-white-900);
		@extend %p-4;
		@extend %mb-4;
		@extend %font-14-pm;
		&:last-of-type {
			margin-bottom: 0;
		}
	}
	.body-subwrap {
		@extend %pb-8;
		&:last-of-type {
			padding-bottom: 0;
		}
	}
	.body-content {
		@extend %pb-8;
		color: var(--neutral-600);
		&:last-of-type {
			padding-bottom: 0;
		}
		a {
			border-bottom: none;
			color: var(--neutral-600);
		}
		.phone {
			border-bottom: none;
		}
	}
	.subtitle {
		@extend %font-16-pb;
		@extend %pb-6;
	}
	.static-list {
		@extend %pl-6;
		@extend %pr-4;
		li {
			padding-inline: var(--space-2) 0;
			list-style-type: lower-alpha;
			@extend %pb-6;
		}
		li::marker {
			@extend %font-16-pb;
		}
		li:last-child {
			padding-bottom: 0;
		}
	}
	.static-sublist {
		li {
			padding-inline: var(--space-2) 0;
			list-style-type: lower-roman;
			@extend %pb-6;
		}
		li::marker {
			font-weight: 400;
		}
		li:last-child {
			padding-bottom: 0;
		}
	}
	.social-link-wrapper {
		@extend %flex;
		gap: var(--space-3);
	}
	.social-link {
		&-anchor {
			width: 2.6rem;
			height: 2.6rem;
			border: 0.1rem solid clr(pure-white-900);
			@extend %p-2;
			@extend %d-block;
			@extend %circle-radius;
			@extend %hidden;
			@extend %flex-c-c;
			@extend %pure-white-900;
			background-color: var(--primary-200);
			color: var(--secondary-800);
		}
		@each $key, $value in $social {
			&-#{$key}::before {
				@include icon($key);
			}
		}
	}
}
.waf-error {
	text-align: center;
	height: calc(var(--window-inner-height) - var(--header-height));
	.layout-wrapper {
		@extend %h-100;
		@extend %py-10;
		@extend %px-4;
	}
	.title {
		font-size: 4rem;
		font-weight: 700;
		@extend %pb-1;
	}
	.sub-text {
		font-size: 2.5rem;
		font-weight: 500;
		@extend %pb-4;
	}
	.thumbnail-image {
		width: 30rem;
		height: 25.0rem;
		@extend %pb-8;
		margin: auto;
	}
	.error-content {
		font-size: 1.5rem;
		font-weight: 400;
		@extend %pb-5;
		button {
			width: 100%;
		}
	}
	.content-wrap {
		@include drop-arrow();
		--_more-color: var(--secondary-800);
	}
	.btn-site a {
		border-radius: 0.5rem;
		font-weight: 700;
		font-size: 1rem;
		justify-content: center;
		background: var(--primary-300);
		color: var(--secondary-800);
		@extend %p-2-4;
	}
}
.waf-wisden-story {
	@extend %m-4;
	.waf-head {
		@extend %mb-4;
		.title {
			@extend %uppercase;
			@extend %secondary-800;
			@extend %font-20-pb;
		}
	}
	.waf-body {
		@extend %half-radius;
		@extend %p-4;
		@extend %pure-white-900-bg;
		.article-featured-media {
			@extend %mb-6;
		}
		.title {
			@extend %mb-6;
			@extend %secondary-800;
			@extend %font-24-pb;
		}
		.drop-cap {
			@extend %mb-6;
			@extend %font-20-pb;
			@extend %neutral-800;
			&:first-letter {
				float: left;
				font-size: 4.8rem;
				line-height: 1;
			}
		}
		.sub-title {
			@extend %mb-6;
			@extend %font-20-pb;
			@extend %neutral-800;
		}
		.text {
			@extend %mb-6;
			@extend %neutral-800;
			@extend %font-14-pr;
			a {
				font-weight: 700;
				@extend %primary-700;
				&:hover {
					text-decoration: underline;
					color: var(--primary-700);
				}
			}
		}
		a {
			@extend %font-14-pb;
			@extend %primary-700;
			&:hover {
				text-decoration: underline;
				color: var(--primary-700);
			}
		}
	}
	.btn-group {
		border-top: .1rem solid var(--neutral-50);
		@extend %pt-8;
		@extend %flex-column;
		@extend %gap-4;
		.btn-link {
			height: 3.4rem;
			width: max-content;
			border: .1rem solid var(--secondary-800);
			@extend %gap-3;
			@extend %font-12-pb;
			@extend %px-4;
			@extend %capitalize;
			@extend %secondary-800;
			@extend %half-radius;
			@extend %flex-c-c;
			&:before {
				@include icon(drop-arrow, 12);
			}
		}
	}
}
@include mq(col-md) {
	.waf-static {
		.layout-wrapper {
			padding: var(--space-15);
		}
		.title {
			padding-bottom: var(--space-8);
			font-size: 2.4rem;
		}
		.body-wrap {
			line-height: var(--space-6);
			padding: var(--space-6);
			font-size: 1.6rem;
			font-weight: 400
		}
		.subtitle {
			font-size: 2rem;
		}
		.static-list {
			li::marker {
				font-size: 2rem;
			}
		}
		.body-content {
			a {
				border-bottom: 0.1rem solid var(--neutral-600);
				color: var(--neutral-600);
			}
		}
		.waf-error {
			text-align: left;
			.error-content {
				button {
					width: 20rem;
				}
			}
			.thumbnail {
				width: 50%;
			}
			.thumbnail-image {
				width: 39.0rem;
				height: 32.0rem;
			}
			.btn-site a {
				background: transparent;
			}
			.content {
				text-align: left;
				padding: var(--space-0);
				width: 50%;
			}
			.content-wrap {
				--_more-color: var(--secondary-800);
				--_border-color: var(--secondary-800);
			}
			.layout-wrapper {
				display: flex;
				align-items: center;
				gap: var(--space-10);
			}
		}
		.waf-wisden-story {
			margin-inline: 0;
			margin-block: var(--space-8);
		}
	}
	.waf-wisden-story {
		margin-inline: 0;
	}
}
@include mq(col-lg) {
	.waf-wisden-story {
		.waf-head {
			margin-bottom: var(--space-7);
			.title {
				font-size: 2.4rem;
			}
		}
		.waf-body {
			.title {
				font-size: 3.6rem;
				line-height: 4rem;
				margin-bottom: var(--space-7);
			}
			.sub-title {
				margin-bottom: var(--space-7);
			}
			.text {
				font-size: 1.6rem;
				line-height: 2.2rem;
				margin-bottom: var(--space-7);
				a {
					line-height: 2.2rem;
					font-size: 1.6rem;	
				}
			}
			a {
				line-height: 2.2rem;
				font-size: 1.6rem;
			}
			.article-featured-media {
				margin-bottom: var(--space-7);
			}
		}
		.btn-group {
			flex-direction: row;
		}
	}
	.waf-listing {
		&.related-list {
			margin-top: 7rem;
		}
	}
	.waf-error {
		text-align: left;
		.error-content {
			button {
				width: 20rem;
			}
		}
		.thumbnail {
			width: 50%;
		}
		.thumbnail-image {
			width: 39.0rem;
			height: 32.0rem;
		}
		.btn-site a {
			background: transparent;
			&:hover {
				--_border-color: var(--primary-300);
				background: var(--primary-300);
			}
		}
		.content {
			text-align: left;
			padding: var(--space-0);
			width: 50%;
		}
		.content-wrap {
			--_more-color: var(--secondary-800);
			--_border-color: var(--secondary-800);
		}
		.layout-wrapper {
			display: flex;
			align-items: center;
			gap: var(--space-10);
		}
	}
	.sticky-container-detail {
        padding-block: var(--space-5);
    }
}